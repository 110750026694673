import React, { useState, useEffect } from 'react';
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Label } from "./components/ui/label";
import { Textarea } from "./components/ui/textarea";
import { Badge } from "./components/ui/badge";
import { Separator } from "./components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./components/ui/dialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import { AlertCircle, FileText, Users, Building2, CalendarClock, GraduationCap, ExternalLink, Globe } from 'lucide-react';

const InvestigationDialog = ({ 
  isOpen, 
  onClose, 
  selectedNode, 
  networkData,  // This now includes rawData
  onStartInvestigation 
}) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [formData, setFormData] = useState({
    title: selectedNode ? `Investigation of ${selectedNode.id}'s Research Network` : '',
    priority: 'medium',
    assignedTo: '',
    description: '',
    tags: []
  });

  // Update title when selectedNode changes
  useEffect(() => {
    if (selectedNode) {
      setFormData(prev => ({
        ...prev,
        title: `Investigation of ${selectedNode.id}'s Research Network`
      }));
    }
  }, [selectedNode]);

  if (!selectedNode) return null;

  // Find all related nodes (collaborators and their organizations)
  const relatedConnections = networkData.links
    .filter(link => 
      link.source.id === selectedNode.id || link.target.id === selectedNode.id
    )
    .map(link => {
      const connectedNode = link.source.id === selectedNode.id ? link.target : link.source;
      return {
        ...connectedNode,
        connectionType: link.type
      };
    });

  // First find risky collaborators
  const riskyCollaborators = relatedConnections.filter(node => 
    node.risk !== 'Low' && node.group === 'Co-author Name'
  );

  // Then find organizations connected to those risky collaborators
  const organizations = new Set();
  riskyCollaborators.forEach(collaborator => {
    networkData.links
      .filter(link => 
        (link.source.id === collaborator.id || link.target.id === collaborator.id) &&
        (link.type === 'affiliation' || link.connectionType === 'affiliation')
      )
      .forEach(link => {
        const orgNode = link.source.id === collaborator.id ? link.target : link.source;
        if (orgNode.group === 'Co-author Org Name') {
          organizations.add(orgNode);
        }
      });
  });

  const uniqueOrganizations = Array.from(organizations);

  // Add this helper function to get publications
  const getPublicationsForNode = (node) => {
    if (!networkData.rawData) return [];
  
    // Create a Set to track unique publications using title as the key
    const uniquePublications = new Map();
  
    networkData.rawData
      .filter(row => row.Name === node.id || row['Author Name'] === node.id)
      .forEach(row => {
        // Create a unique key from the publication title (or URL if title is missing)
        const uniqueKey = row.Publication_Title || row.Publication_URL;
        
        if (uniqueKey && !uniquePublications.has(uniqueKey)) {
          uniquePublications.set(uniqueKey, {
            Publication_Title: row.Publication_Title,
            Publication_URL: row.Publication_URL,
            Scholar_URL: row.Scholar_URL,
            Publication_Venue: row.Publication_Venue,
            Publication_Date: row.Publication_Date
          });
        }
      });
  
    // Convert Map back to array and sort by date (most recent first)
    return Array.from(uniquePublications.values())
      .sort((a, b) => {
        // Handle missing dates by putting them at the end
        if (!a.Publication_Date) return 1;
        if (!b.Publication_Date) return -1;
        return new Date(b.Publication_Date) - new Date(a.Publication_Date);
      });
  };

  const handleSubmit = () => {
    onStartInvestigation({
      ...formData,
      subjectId: selectedNode.id,
      subjectType: selectedNode.group,
      riskyCollaborators: riskyCollaborators.map(c => ({
        name: c.id,
        risk: c.risk
      })),
      organizations: uniqueOrganizations.map(o => ({
        name: o.id
      })),
      status: 'open',
      createdAt: new Date().toISOString()
    });
    onClose();
  };

  const PublicationsSection = ({ publications }) => {
    return (
      <div className="mt-4">
        <h4 className="text-sm font-medium text-gray-700 mb-2">Publications</h4>
        <div className="space-y-4">
          {publications.map((pub, index) => (
            <div key={index} className="bg-white p-4 rounded-lg border border-gray-200">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <h5 className="font-medium text-gray-900">{pub.Publication_Title}</h5>
                  {pub.Publication_Venue && (
                    <p className="text-sm text-gray-600 mt-1">{pub.Publication_Venue}</p>
                  )}
                  {pub.Publication_Date && (
                    <p className="text-sm text-gray-500 mt-1">{pub.Publication_Date}</p>
                  )}
                </div>
              </div>
              
              {/* Publication Links */}
              <div className="mt-3 flex items-center gap-3">
                {pub.Scholar_URL && (
                  <a
                    href={pub.Scholar_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-xs text-blue-600 hover:text-blue-800"
                  >
                    <GraduationCap className="w-3 h-3 mr-1" />
                    Google Scholar
                    <ExternalLink className="w-3 h-3 ml-1" />
                  </a>
                )}
                {pub.Publication_URL && (
                  <a
                    href={pub.Publication_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-xs text-blue-600 hover:text-blue-800"
                  >
                    <Globe className="w-3 h-3 mr-1" />
                    Publication
                    <ExternalLink className="w-3 h-3 ml-1" />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Start New Investigation</DialogTitle>
          <DialogDescription>
            Create an investigation case based on network analysis findings
          </DialogDescription>
        </DialogHeader>

        <Tabs defaultValue="overview" className="w-full" value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="connections">Connections</TabsTrigger>
            <TabsTrigger value="details">Case Details</TabsTrigger>
          </TabsList>

          <TabsContent value="overview">
            <Card>
              <CardHeader>
                <CardTitle>Subject Information</CardTitle>
                <CardDescription>Key information about the subject of investigation</CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="flex items-start space-x-4">
                  <Users className="h-5 w-5 mt-1 text-gray-500" />
                  <div>
                    <h4 className="font-medium">Subject</h4>
                    <p className="text-sm text-gray-500">{selectedNode.id}</p>
                    <Badge variant={selectedNode.risk === 'Low' ? 'secondary' : 'destructive'}>
                      {selectedNode.risk} Risk
                    </Badge>
                  </div>
                </div>

                <Separator />

                <div className="flex items-start space-x-4">
                  <AlertCircle className="h-5 w-5 mt-1 text-gray-500" />
                  <div>
                    <h4 className="font-medium">Risk Summary</h4>
                    <p className="text-sm text-gray-500">
                      {riskyCollaborators.length} risky collaborators identified across {uniqueOrganizations.length} organizations
                    </p>
                  </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h5 className="font-medium mb-2">Quick Actions</h5>
                  <div className="space-x-2">
                    <Button variant="outline" size="sm" onClick={() => setActiveTab('connections')}>
                      View Network Details
                    </Button>
                    {/* <Button variant="outline" size="sm">
                      Download Evidence
                    </Button> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="connections">
            <Card>
              <CardHeader>
                <CardTitle>Network Connections</CardTitle>
                <CardDescription>Related collaborators and organizations</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-6">
                  <div>
                    <h4 className="font-medium mb-2 flex items-center">
                      <Users className="h-4 w-4 mr-2" />
                      Risky Collaborators
                    </h4>
                    <div className="space-y-2">
                      {riskyCollaborators.map(collaborator => (
                        <div key={collaborator.id} className="flex items-center justify-between bg-gray-50 p-3 rounded">
                          <div>
                            <p className="font-medium">{collaborator.id}</p>
                            <p className="text-sm text-gray-500">Co-author</p>
                          </div>
                          <Badge variant="destructive">{collaborator.risk} Risk</Badge>
                        </div>
                      ))}
                    </div>
                  </div>

                  <Separator />

                  <div>
                    <h4 className="font-medium mb-2 flex items-center">
                      <Building2 className="h-4 w-4 mr-2" />
                      Connected Organizations
                    </h4>
                    <div className="space-y-2">
                      {uniqueOrganizations.map(org => (
                        <div key={org.id} className="flex items-center justify-between bg-gray-50 p-3 rounded">
                          <div>
                            <p className="font-medium">{org.id}</p>
                            <p className="text-sm text-gray-500">Research Institution</p>
                          </div>
                          <Badge variant={org.risk === 'Low' ? 'secondary' : 'destructive'}>
                            {org.risk} Risk
                          </Badge>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium mb-2 flex items-center">
                      <FileText className="h-4 w-4 mr-2" />
                      Related Publications
                    </h4>
                    <PublicationsSection publications={getPublicationsForNode(selectedNode)} />
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="details">
            <Card>
              <CardHeader>
                <CardTitle>Case Details</CardTitle>
                <CardDescription>Define the parameters for this investigation</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="title">Case Title</Label>
                    <Input
                      id="title"
                      value={formData.title}
                      onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="priority">Priority Level</Label>
                    <select
                      id="priority"
                      className="w-full rounded-md border border-input p-2"
                      value={formData.priority}
                      onChange={(e) => setFormData(prev => ({ ...prev, priority: e.target.value }))}
                    >
                      <option value="low">Low Priority</option>
                      <option value="medium">Medium Priority</option>
                      <option value="high">High Priority</option>
                      <option value="urgent">Urgent</option>
                    </select>
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="assignedTo">Assigned To</Label>
                    <Input
                      id="assignedTo"
                      placeholder="Enter email address"
                      value={formData.assignedTo}
                      onChange={(e) => setFormData(prev => ({ ...prev, assignedTo: e.target.value }))}
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="description">Initial Notes</Label>
                    <Textarea
                      id="description"
                      placeholder="Enter any initial observations or concerns..."
                      value={formData.description}
                      onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                      className="min-h-[100px]"
                    />
                  </div>

                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-center space-x-2">
                      <CalendarClock className="h-4 w-4 text-gray-500" />
                      <span className="text-sm text-gray-500">
                        Investigation will be created with timestamp and unique ID
                      </span>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled>Start Investigation</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InvestigationDialog;
