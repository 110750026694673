import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import * as d3 from 'd3';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './components/ui/select';
// import { Checkbox } from './components/ui/checkbox';
import { Input } from './components/ui/input';
import { Button } from './components/ui/button';
import { Minus, Plus } from 'lucide-react';
import InvestigationDialog from './InvestigationDialog';

const NetworkGraph = ({ token }) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [data, setData] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [filters, setFilters] = useState({
    riskLevels: 'HIGH',
    showOnlyRisky: false,
    researcherName: 'all',
    coauthorName: 'all',
    coauthorOrgName: 'all',
    project: 'all',
    folder: 'all',
    subfolder: 'all'
  });
  const [selectedNode, setSelectedNode] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [filterOptions, setFilterOptions] = useState({
    researchers: new Set(),
    coauthors: new Set(),
    coauthorOrgs: new Set(),
    projects: new Set(),
    folders: new Set(),    // Add these
    subfolders: new Set()  // new sets
  });
  const [showInvestigation, setShowInvestigation] = useState(false);

  // Dimension handling
  const updateDimensions = useCallback(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      const minWidth = 100;
      const minHeight = 500;
      
      setDimensions({
        width: Math.max(width, minWidth),
        height: Math.max(height, minHeight)
      });
    }
  }, []);

  // Add a resize listener as well
  useEffect(() => {
    const handleResize = () => {
      updateDimensions();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [updateDimensions]);

  // Data processing
  const processData = (rawData) => {
    const nodes = new Set();
    const links = [];
    const riskMap = new Map();
    const projectMap = new Map();

    rawData.forEach(row => {
      const clientUniversity = row['Collaborator Org Name'];
      const clientResearcher = row['Name'];
      const collaborator = row['Author Name'];
      const collaboratorOrg = row['Subject Org Name'];
      const risk = row['Risk'] || 'Low';
      const project = row['Project'] || '';

      nodes.add(clientUniversity);
      nodes.add(clientResearcher);
      nodes.add(collaborator);
      nodes.add(collaboratorOrg);

      links.push({ source: clientUniversity, target: clientResearcher, type: 'affiliation' });
      links.push({ 
        source: clientResearcher, 
        target: collaborator, 
        type: 'collaboration',
        project: project
      });
      links.push({ source: collaborator, target: collaboratorOrg, type: 'affiliation' });

      riskMap.set(collaborator, risk);
      riskMap.set(collaboratorOrg, risk);
      
      if (project) {
        projectMap.set(clientResearcher, project);
        projectMap.set(collaborator, project);
      }
    });

    return {
      nodes: Array.from(nodes).map(id => ({ 
        id, 
        group: rawData.some(row => row['Collaborator Org Name'] === id) ? 'Collaborator Org Name' :
               rawData.some(row => row['Name'] === id) ? 'Researcher Name' :
               rawData.some(row => row['Author Name'] === id) ? 'Co-author Name' : 'Co-author Org Name',
        risk: riskMap.get(id) || 'Low',
        project: projectMap.get(id) || ''
      })),
      links: links,
      rawData: rawData
    };
  };

  // Add this after the processData function
  const updateFilterOptions = useCallback((data) => {
    if (!data || !data.rawData) return;  // Add this guard clause

    const options = {
      researchers: new Set(),
      coauthors: new Set(),
      coauthorOrgs: new Set(),
      projects: new Set(),
      folders: new Set(),
      subfolders: new Set()
    };

    data.rawData.forEach(row => {
      // Project, Folder, Subfolder filters
      if (row.Project) options.projects.add(row.Project);
      
      if (filters.project === 'all' || row.Project === filters.project) {
        if (row.Folder) options.folders.add(row.Folder);
      }
      
      if ((filters.project === 'all' || row.Project === filters.project) &&
          (filters.folder === 'all' || row.Folder === filters.folder)) {
        if (row.Subfolder) options.subfolders.add(row.Subfolder);
      }

      // Name filters - using actual column names from CSV
      if (row['Name']) options.researchers.add(row['Name']);
      if (row['Author Name']) options.coauthors.add(row['Author Name']);
      if (row['Subject Org Name']) options.coauthorOrgs.add(row['Subject Org Name']);
    });

    setFilterOptions(options);
  }, [filters.project, filters.folder]);

  // Data loading
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://beta.researcher-network-viz.pages.dev/api/network-data', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const jsonData = await response.json();
        const processedData = processData(jsonData);
        setData(processedData);
        updateFilterOptions(processedData);
        setError(null);
        
        setTimeout(() => {
          updateDimensions();
        }, 0);
        
      } catch (err) {
        console.error('Failed to load network data:', err);
        setError('Failed to load network data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (token) loadData();
  }, [token, updateDimensions, updateFilterOptions]);

  // Load comments
  useEffect(() => {
    const loadComments = async () => {
      try {
        const response = await fetch('https://beta.researcher-network-viz.pages.dev/api/comments', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to load comments');
        
        const commentsData = await response.json();
        const commentsByNode = commentsData.reduce((acc, comment) => {
          if (!acc[comment.node_id]) acc[comment.node_id] = [];
          acc[comment.node_id].push({
            id: comment.id,
            text: comment.text,
            timestamp: comment.created_at,
            email: comment.user_email  // Add this line
          });
          return acc;
        }, {});
        
        setComments(commentsByNode);
      } catch (err) {
        console.error('Failed to load comments:', err);
      }
    };

    if (token) loadComments();
  }, [token]);

  const filteredData = useMemo(() => {
    if (!data) return null;

    let filteredNodes = data.nodes;
    let nodeIds = new Set();

    // Add project/folder/subfolder filter
    if (filters.project !== 'all' || filters.folder !== 'all' || filters.subfolder !== 'all') {
      const matchingRows = data.rawData.filter(row => 
        (filters.project === 'all' || row.Project === filters.project) &&
        (filters.folder === 'all' || row.Folder === filters.folder) &&
        (filters.subfolder === 'all' || row.Subfolder === filters.subfolder)
      );

      const matchingNodeIds = new Set();
      matchingRows.forEach(row => {
        if (row.Name) matchingNodeIds.add(row.Name);
        if (row['Author Name']) matchingNodeIds.add(row['Author Name']); 
        if (row['Collaborator Org Name']) matchingNodeIds.add(row['Collaborator Org Name']);
        if (row['Subject Org Name']) matchingNodeIds.add(row['Subject Org Name']);
      });

      filteredNodes = filteredNodes.filter(node => matchingNodeIds.has(node.id));
    }

    // Apply name-based filters
    if (filters.researcherName !== 'all' || filters.coauthorName !== 'all' || filters.coauthorOrgName !== 'all') {
      const searchTerms = {
        'Researcher Name': filters.researcherName === 'all' ? '' : filters.researcherName,
        'Co-author Name': filters.coauthorName === 'all' ? '' : filters.coauthorName,
        'Co-author Org Name': filters.coauthorOrgName === 'all' ? '' : filters.coauthorOrgName
      };

      // First, find nodes that match the search criteria
      const matchingNodes = new Set();
      const connectedNodes = new Set();
      const secondaryConnections = new Set();
      const tertiaryConnections = new Set(); // For researcher orgs when filtering by co-author org

      filteredNodes.forEach(node => {
        const searchTerm = searchTerms[node.group];
        if (searchTerm && node.id === searchTerm) {
          matchingNodes.add(node.id);
          
          // Find directly connected nodes
          data.links.forEach(link => {
            const sourceId = link.source.id || link.source;
            const targetId = link.target.id || link.target;
            
            if (sourceId === node.id) {
              connectedNodes.add(targetId);
              
              // If we're filtering by co-author org, find connected co-authors and their researchers
              if (node.group === 'Co-author Org Name') {
                data.links.forEach(secondaryLink => {
                  const secSourceId = secondaryLink.source.id || secondaryLink.source;
                  const secTargetId = secondaryLink.target.id || secondaryLink.target;
                  
                  // Find researchers connected to the co-authors
                  if (secSourceId === targetId || secTargetId === targetId) {
                    const connectedNode = data.nodes.find(n => n.id === (secSourceId === targetId ? secTargetId : secSourceId));
                    if (connectedNode && connectedNode.group === 'Researcher Name') {
                      secondaryConnections.add(connectedNode.id);
                      
                      // Find researcher organizations
                      data.links.forEach(tertiaryLink => {
                        const tertSourceId = tertiaryLink.source.id || tertiaryLink.source;
                        const tertTargetId = tertiaryLink.target.id || tertiaryLink.target;
                        if (tertSourceId === connectedNode.id || tertTargetId === connectedNode.id) {
                          const orgNode = data.nodes.find(n => n.id === (tertSourceId === connectedNode.id ? tertTargetId : tertSourceId));
                          if (orgNode && orgNode.group === 'Collaborator Org Name') {
                            tertiaryConnections.add(orgNode.id);
                          }
                        }
                      });
                    }
                  }
                });
              }
              // Existing logic for researcher and co-author filtering
              else if (node.group === 'Researcher Name' || node.group === 'Co-author Name') {
                data.links.forEach(secondaryLink => {
                  const secSourceId = secondaryLink.source.id || secondaryLink.source;
                  const secTargetId = secondaryLink.target.id || secondaryLink.target;
                  if (secSourceId === targetId || secTargetId === targetId) {
                    const connectedNode = data.nodes.find(n => n.id === (secSourceId === targetId ? secTargetId : secSourceId));
                    if (connectedNode && 
                        (connectedNode.group === 'Co-author Org Name' || 
                         connectedNode.group === 'Collaborator Org Name')) {
                      secondaryConnections.add(connectedNode.id);
                    }
                  }
                });
              }
            }
            // Repeat the same logic for target connections
            if (targetId === node.id) {
              connectedNodes.add(sourceId);
              if (node.group === 'Co-author Org Name') {
                data.links.forEach(secondaryLink => {
                  const secSourceId = secondaryLink.source.id || secondaryLink.source;
                  const secTargetId = secondaryLink.target.id || secondaryLink.target;
                  
                  if (secSourceId === sourceId || secTargetId === sourceId) {
                    const connectedNode = data.nodes.find(n => n.id === (secSourceId === sourceId ? secTargetId : secSourceId));
                    if (connectedNode && connectedNode.group === 'Researcher Name') {
                      secondaryConnections.add(connectedNode.id);
                      
                      data.links.forEach(tertiaryLink => {
                        const tertSourceId = tertiaryLink.source.id || tertiaryLink.source;
                        const tertTargetId = tertiaryLink.target.id || tertiaryLink.target;
                        if (tertSourceId === connectedNode.id || tertTargetId === connectedNode.id) {
                          const orgNode = data.nodes.find(n => n.id === (tertSourceId === connectedNode.id ? tertTargetId : tertSourceId));
                          if (orgNode && orgNode.group === 'Collaborator Org Name') {
                            tertiaryConnections.add(orgNode.id);
                          }
                        }
                      });
                    }
                  }
                });
              }
              else if (node.group === 'Researcher Name' || node.group === 'Co-author Name') {
                data.links.forEach(secondaryLink => {
                  const secSourceId = secondaryLink.source.id || secondaryLink.source;
                  const secTargetId = secondaryLink.target.id || secondaryLink.target;
                  if (secSourceId === sourceId || secTargetId === sourceId) {
                    const connectedNode = data.nodes.find(n => n.id === (secSourceId === sourceId ? secTargetId : secSourceId));
                    if (connectedNode && 
                        (connectedNode.group === 'Co-author Org Name' || 
                         connectedNode.group === 'Collaborator Org Name')) {
                      secondaryConnections.add(connectedNode.id);
                    }
                  }
                });
              }
            }
          });
        }
      });

      // If any search terms are active, filter nodes to show matching, connected, and all secondary/tertiary nodes
      if (matchingNodes.size > 0) {
        filteredNodes = filteredNodes.filter(node => 
          matchingNodes.has(node.id) || 
          connectedNodes.has(node.id) || 
          secondaryConnections.has(node.id) ||
          tertiaryConnections.has(node.id)
        );
      }
    }

    // Apply risk level filter with full path tracing
    if (filters.riskLevels === 'risky') {
      // First identify nodes with HIGH or MEDIUM risk
      const riskyNodeIds = new Set();
      const connectedResearcherIds = new Set();
      const connectedUniversityIds = new Set();

      // Find nodes with risky levels and their direct connections
      data.links.forEach(link => {
        const sourceNode = data.nodes.find(n => n.id === (link.source.id || link.source));
        const targetNode = data.nodes.find(n => n.id === (link.target.id || link.target));
        
        if (sourceNode && targetNode) {
          // If either node is risky (HIGH or MEDIUM)
          if (['HIGH', 'MEDIUM'].includes(sourceNode.risk) || 
              ['HIGH', 'MEDIUM'].includes(targetNode.risk)) {
            riskyNodeIds.add(sourceNode.id);
            riskyNodeIds.add(targetNode.id);
            
            // If one node is a researcher, add it
            if (sourceNode.group === 'Researcher Name') {
              connectedResearcherIds.add(sourceNode.id);
            }
            if (targetNode.group === 'Researcher Name') {
              connectedResearcherIds.add(targetNode.id);
            }
          }
        }
      });

      // Find universities connected to the identified researchers
      data.links.forEach(link => {
        const sourceNode = data.nodes.find(n => n.id === (link.source.id || link.source));
        const targetNode = data.nodes.find(n => n.id === (link.target.id || link.target));
        
        if (sourceNode && targetNode) {
          if (connectedResearcherIds.has(sourceNode.id) && targetNode.group === 'Collaborator Org Name') {
            connectedUniversityIds.add(targetNode.id);
          }
          if (connectedResearcherIds.has(targetNode.id) && sourceNode.group === 'Collaborator Org Name') {
            connectedUniversityIds.add(sourceNode.id);
          }
        }
      });

      // Combine all relevant nodes
      const allRelevantNodeIds = new Set([
        ...riskyNodeIds,
        ...connectedResearcherIds,
        ...connectedUniversityIds
      ]);

      filteredNodes = filteredNodes.filter(node => allRelevantNodeIds.has(node.id));
    } else if (filters.riskLevels !== 'all') {
      // First identify nodes with the selected risk level
      const riskyNodeIds = new Set();
      const connectedResearcherIds = new Set();
      const connectedUniversityIds = new Set();

      // Find nodes with matching risk level and their direct connections
      data.links.forEach(link => {
        const sourceNode = data.nodes.find(n => n.id === (link.source.id || link.source));
        const targetNode = data.nodes.find(n => n.id === (link.target.id || link.target));
        
        if (sourceNode && targetNode) {
          // If either node matches the selected risk level
          if (sourceNode.risk === filters.riskLevels || targetNode.risk === filters.riskLevels) {
            riskyNodeIds.add(sourceNode.id);
            riskyNodeIds.add(targetNode.id);
            
            // If one node is a researcher, add it
            if (sourceNode.group === 'Researcher Name') {
              connectedResearcherIds.add(sourceNode.id);
            }
            if (targetNode.group === 'Researcher Name') {
              connectedResearcherIds.add(targetNode.id);
            }
          }
        }
      });

      // Find universities connected to the identified researchers
      data.links.forEach(link => {
        const sourceNode = data.nodes.find(n => n.id === (link.source.id || link.source));
        const targetNode = data.nodes.find(n => n.id === (link.target.id || link.target));
        
        if (sourceNode && targetNode) {
          if (connectedResearcherIds.has(sourceNode.id) && targetNode.group === 'Collaborator Org Name') {
            connectedUniversityIds.add(targetNode.id);
          }
          if (connectedResearcherIds.has(targetNode.id) && sourceNode.group === 'Collaborator Org Name') {
            connectedUniversityIds.add(sourceNode.id);
          }
        }
      });

      // Combine all relevant nodes
      const allRelevantNodeIds = new Set([
        ...riskyNodeIds,
        ...connectedResearcherIds,
        ...connectedUniversityIds
      ]);

      filteredNodes = filteredNodes.filter(node => allRelevantNodeIds.has(node.id));
    }

    // Apply risky collaborations filter
    if (filters.showOnlyRisky) {
      // First, identify risky nodes and their direct connections
      const riskyNodeIds = new Set();
      const connectedResearcherIds = new Set();
      const connectedUniversityIds = new Set();

      // Find risky nodes and their connected researchers
      data.links.forEach(link => {
        const sourceNode = data.nodes.find(n => n.id === (link.source.id || link.source));
        const targetNode = data.nodes.find(n => n.id === (link.target.id || link.target));
        
        if (sourceNode && targetNode) {
          // If either node is risky (not Low risk)
          if (sourceNode.risk !== 'Low' || targetNode.risk !== 'Low') {
            riskyNodeIds.add(sourceNode.id);
            riskyNodeIds.add(targetNode.id);
            
            // If one node is a researcher, add it
            if (sourceNode.group === 'Researcher Name') {
              connectedResearcherIds.add(sourceNode.id);
            }
            if (targetNode.group === 'Researcher Name') {
              connectedResearcherIds.add(targetNode.id);
            }
          }
        }
      });

      // Find universities connected to the identified researchers
      data.links.forEach(link => {
        const sourceNode = data.nodes.find(n => n.id === (link.source.id || link.source));
        const targetNode = data.nodes.find(n => n.id === (link.target.id || link.target));
        
        if (sourceNode && targetNode) {
          if (connectedResearcherIds.has(sourceNode.id) && targetNode.group === 'Collaborator Org Name') {
            connectedUniversityIds.add(targetNode.id);
          }
          if (connectedResearcherIds.has(targetNode.id) && sourceNode.group === 'Collaborator Org Name') {
            connectedUniversityIds.add(sourceNode.id);
          }
        }
      });

      // Combine all relevant nodes
      const allRelevantNodeIds = new Set([
        ...riskyNodeIds,
        ...connectedResearcherIds,
        ...connectedUniversityIds
      ]);

      filteredNodes = filteredNodes.filter(node => allRelevantNodeIds.has(node.id));
    }

    // Add all remaining nodes to nodeIds set
    filteredNodes.forEach(node => nodeIds.add(node.id));

    // Filter links to only include those between remaining nodes
    let filteredLinks = data.links.filter(l => 
      nodeIds.has(l.source.id || l.source) && nodeIds.has(l.target.id || l.target)
    );

    return { nodes: filteredNodes, links: filteredLinks };
  }, [data, filters]);

  // D3 visualization
  useEffect(() => {
    if (!filteredData || !svgRef.current || dimensions.width === 0 || dimensions.height === 0) {
      return;
    }

    const svg = d3.select(svgRef.current);
    const { width, height } = dimensions;

    svg.attr('width', width).attr('height', height);
    svg.selectAll('*').remove();

    const color = d3.scaleOrdinal()
      .domain(['Collaborator Org Name', 'Researcher Name', 'Co-author Name', 'Co-author Org Name'])
      .range(['#1f77b4', '#2ca02c', '#ff7f0e', '#d62728']);

    const riskColor = d3.scaleOrdinal()
      .domain(['HIGH', 'MEDIUM', 'LOW', 'UNCLASSIFIED'])
      .range(['#ff0000', '#ffa500', '#999999', '#808080']);

    const simulation = d3.forceSimulation(filteredData.nodes)
      .force('link', d3.forceLink(filteredData.links).id(d => d.id).distance(100))
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collision', d3.forceCollide().radius(30));

    const zoom = d3.zoom()
      .scaleExtent([0.1, 4])
      .on('zoom', (event) => g.attr('transform', event.transform));

    svg.call(zoom);
    const g = svg.append('g');

    const link = g.append('g')
      .selectAll('line')
      .data(filteredData.links)
      .join('line')
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6)
      .attr('stroke-width', 1);

    const node = g.append('g')
      .selectAll('g')
      .data(filteredData.nodes)
      .join('g')
      .call(d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended))
      .on('click', (event, d) => {
        event.stopPropagation();
        setSelectedNode(d);
      });

    node.each(function(d) {
      const el = d3.select(this);
      if (['Researcher Name', 'Co-author Name'].includes(d.group)) {
        el.append('svg')
          .attr('width', 24)
          .attr('height', 24)
          .attr('viewBox', '0 0 960 960')
          .attr('x', -12)  // Center the icon
          .attr('y', -12)  // Center the icon
          .append('path')
          .attr('d', 'M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z')
          .attr('fill', color(d.group))
          .attr('transform', 'translate(0, 960)')
          .attr('stroke', d => d.risk !== 'Low' ? riskColor(d.risk) : 'none')
          .attr('stroke-width', d => d.risk !== 'Low' ? 2 : 0);
      } else {
        el.append('svg')
          .attr('width', 24)
          .attr('height', 24)
          .attr('viewBox', '0 0 960 960')
          .attr('x', -12)  // Center the icon
          .attr('y', -12)  // Center the icon
          .append('path')
          .attr('d', 'M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z')
          .attr('fill', color(d.group))
          .attr('transform', 'translate(0, 960)')
          .attr('stroke', d => d.risk !== 'Low' ? riskColor(d.risk) : 'none')
          .attr('stroke-width', d => d.risk !== 'Low' ? 2 : 0);
      }
    });

    node.append('title')
      .text(d => `${d.id}\nType: ${d.group}\nRisk: ${d.risk}`);

    node.append('text')
      .text(d => d.id)
      .attr('x', 12)
      .attr('y', 4)
      .style('font-size', '10px')
      .style('fill', d => d.risk !== 'Low' ? riskColor(d.risk) : '#666')
      // Add font-weight styling based on node type and risk level
      .style('font-weight', d => 
        ['Co-author Name', 'Co-author Org Name'].includes(d.group) && d.risk !== 'Low' ? 'bold' : 'normal'
      );

    // Add comment indicators
    node.filter(d => comments[d.id])
      .append('circle')
      .attr('r', 4)
      .attr('cx', -10)
      .attr('cy', -10)
      .attr('fill', '#ffd700');

    simulation.on('tick', () => {
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      node.attr('transform', d => `translate(${d.x},${d.y})`);
    });

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    // Add highlight functionality
    node.on('mouseover', highlight)
        .on('mouseout', unhighlight);

    function highlight(event, d) {
      const connectedNodes = new Set([d.id]);
      const relevantLinks = new Set();

      // First pass: find directly connected nodes
      link.each(function(l) {
        if (l.source.id === d.id || l.target.id === d.id) {
          connectedNodes.add(l.source.id);
          connectedNodes.add(l.target.id);
          relevantLinks.add(l);
        }
      });

      // Second pass: specific behavior for different node types
      if (d.group === 'Co-author Org Name') {
        link.each(function(l) {
          if (connectedNodes.has(l.source.id) && l.source.group === 'Co-author Name') {
            if (l.target.group === 'Researcher Name') {
              connectedNodes.add(l.target.id);
              relevantLinks.add(l);
            }
          } else if (connectedNodes.has(l.target.id) && l.target.group === 'Co-author Name') {
            if (l.source.group === 'Researcher Name') {
              connectedNodes.add(l.source.id);
              relevantLinks.add(l);
            }
          }
        });
      } else if (d.group === 'Researcher Name') {
        link.each(function(l) {
          if (l.source.id === d.id && l.target.group === 'Co-author Name') {
            connectedNodes.add(l.target.id);
            relevantLinks.add(l);
            link.each(function(orgLink) {
              if (orgLink.source.id === l.target.id && orgLink.target.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.target.id);
                relevantLinks.add(orgLink);
              } else if (orgLink.target.id === l.target.id && orgLink.source.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.source.id);
                relevantLinks.add(orgLink);
              }
            });
          } else if (l.target.id === d.id && l.source.group === 'Co-author Name') {
            connectedNodes.add(l.source.id);
            relevantLinks.add(l);
            link.each(function(orgLink) {
              if (orgLink.source.id === l.source.id && orgLink.target.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.target.id);
                relevantLinks.add(orgLink);
              } else if (orgLink.target.id === l.source.id && orgLink.source.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.source.id);
                relevantLinks.add(orgLink);
              }
            });
          }
        });
      }

      node.style('opacity', n => connectedNodes.has(n.id) ? 1 : 0.1);
      link.style('opacity', l => relevantLinks.has(l) ? 1 : 0.1);
    }

    function unhighlight() {
      node.style('opacity', 1);
      link.style('opacity', 1);
    }

    // Comment out D3 legends
    // Add node type legend
    // const legend = svg.append('g')
    //   .attr('class', 'legend')
    //   .attr('transform', 'translate(7,20)');

    // const legendData = ['Collaborator Org Name', 'Researcher Name', 'Co-author Name', 'Co-author Org Name'];

    // legendData.forEach((d, i) => {
    //   const legendRow = legend.append('g')
    //     .attr('transform', `translate(0, ${i * 30})`);
    //   
    //   if (['Researcher Name', 'Co-author Name'].includes(d)) {
    //     legendRow.append('path')
    //       .attr('d', 'M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z')
    //       .attr('fill', color(d))
    //       .attr('transform', 'scale(0.03) translate(0, 480)');
    //   } else {
    //     legendRow.append('path')
    //       .attr('d', 'M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z')
    //       .attr('fill', color(d))
    //       .attr('transform', 'scale(0.03) translate(0, 480)');
    //   }
    //   
    //   legendRow.append('text')
    //     .attr('x', 35)
    //     .attr('y', 7)
    //     .text(d)
    //     .style('font-size', '12px');
    // });

    // // Add risk legend
    // const riskLegend = svg.append('g')
    //   .attr('class', 'risk-legend')
    //   .attr('transform', translate(10,${legendData.length * 30 + 40})`);

    // const riskLegendData = ['Very High Risk', 'High Risk', 'Medium Risk', 'Low Risk'];

    // riskLegendData.forEach((d, i) => {
    //   const legendRow = riskLegend.append('g')
    //     .attr('transform', `translate(0, ${i * 25})`);
    //   
    //   legendRow.append('rect')
    //     .attr('width', 40)
    //     .attr('height', 20)
    //     .attr('fill', riskColor(d.split(' ')[0]));
    //   
    //   legendRow.append('text')
    //     .attr('x', 50)
    //     .attr('y', 15)
    //     .text(d)
    //     .style('font-size', '12px')
    //     .style('font-weight', d !== 'Low Risk' ? 'bold' : 'normal');
    // });

    // // Make legends stay in place during zoom
    // legend.attr('transform', `translate(20, 20)`)
    //   .style('pointer-events', 'none');
    // riskLegend.attr('transform', `translate(20, ${legendData.length * 30 + 40})`)
    //   .style('pointer-events', 'none');

  }, [filteredData, dimensions, comments, data]);

  const handleAddComment = async () => {
    if (!selectedNode || !newComment.trim()) return;

    try {
      const response = await fetch('https://beta.researcher-network-viz.pages.dev/api/comments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nodeId: selectedNode.id,
          text: newComment.trim()
        })
      });

      if (!response.ok) throw new Error('Failed to add comment');

      const comment = await response.json();
      setComments(prev => ({
        ...prev,
        [selectedNode.id]: [...(prev[selectedNode.id] || []), {
          id: comment.id,           // Add this line
          text: comment.text,
          timestamp: comment.created_at
        }]
      }));
      
      setNewComment('');
    } catch (err) {
      console.error('Failed to add comment:', err);
    }
  };

  // Add handleDeleteComment function after handleAddComment
  const handleDeleteComment = async (commentId) => {
    if (!selectedNode) return;

    try {
      const response = await fetch('https://beta.researcher-network-viz.pages.dev/api/comments', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          commentId
        })
      });

      if (!response.ok) throw new Error('Failed to delete comment');

      // Update local state by removing the deleted comment
      setComments(prev => ({
        ...prev,
        [selectedNode.id]: prev[selectedNode.id].filter(comment => comment.id !== commentId)
      }));
    } catch (err) {
      console.error('Failed to delete comment:', err);
    }
  };

  const handleZoomChange = (newZoom) => {
    // Clamp zoom between 10% and 200%
    const clampedZoom = Math.max(10, Math.min(200, newZoom));
    setZoomLevel(clampedZoom);
    
    // Apply zoom to the SVG
    if (svgRef.current) {
      const svg = d3.select(svgRef.current);
      const g = svg.select('g');
      g.attr('transform', `scale(${clampedZoom / 100})`);
    }
  };

  const handleStartInvestigation = async (investigationData) => {
    try {
      const response = await fetch('https://beta.researcher-network-viz.pages.dev/api/investigations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(investigationData)
      });

      if (!response.ok) throw new Error('Failed to create investigation');
      
      // Remove the unused variable and just await the response
      await response.json();
      setShowInvestigation(false);
      
    } catch (error) {
      console.error('Failed to start investigation:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="flex h-full">
      <div className="w-64 p-4 border-r border-gray-200 overflow-y-auto">
        {/* Logo section */}
        <div className="mb-6">
          <img 
            src="/Risk Viewer Logo.png" 
            alt="Risk Viewer Logo"
            className="w-full h-auto"
          />
          {/* Decorative separator */}
          <div className="mt-4 flex items-center">
            <div className="flex-grow h-0.5 bg-gradient-to-r from-transparent via-gray-200 to-transparent"></div>
          </div>
        </div>

        <div className="space-y-4">
          {/* Project filter */}
          <div>
            <label className="block text-sm font-medium mb-1">Project</label>
            <Select
              value={filters.project}
              onValueChange={(value) => {
                setFilters(prev => ({
                  ...prev,
                  project: value,
                  folder: 'all',    // Reset child filters
                  subfolder: 'all'  // when parent changes
                }));
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select project..." />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Projects</SelectItem>
                {Array.from(filterOptions.projects).sort().map((project) => (
                  <SelectItem key={project} value={project}>
                    {project}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Folder filter */}
          <div>
            <label className="block text-sm font-medium mb-1">Folder</label>
            <Select
              value={filters.folder}
              onValueChange={(value) => {
                setFilters(prev => ({
                  ...prev,
                  folder: value,
                  subfolder: 'all'  // Reset subfolder when folder changes
                }));
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select folder..." />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Folders</SelectItem>
                {Array.from(filterOptions.folders).sort().map((folder) => (
                  <SelectItem key={folder} value={folder}>
                    {folder}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Subfolder filter */}
          <div>
            <label className="block text-sm font-medium mb-1">Subfolder</label>
            <Select
              value={filters.subfolder}
              onValueChange={(value) => 
                setFilters(prev => ({ ...prev, subfolder: value }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select subfolder..." />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Subfolders</SelectItem>
                {Array.from(filterOptions.subfolders).sort().map((subfolder) => (
                  <SelectItem key={subfolder} value={subfolder}>
                    {subfolder}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Researcher Name filter */}
          <div>
            <label className="block text-sm font-medium mb-1">Researcher Name</label>
            <Select
              value={filters.researcherName}
              onValueChange={(value) => setFilters(prev => ({ ...prev, researcherName: value }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select researcher..." />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Researchers</SelectItem>
                {Array.from(filterOptions.researchers).sort().map((name) => (
                  <SelectItem key={name} value={name}>
                    {name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Co-author Name</label>
            <Select
              value={filters.coauthorName}
              onValueChange={(value) => setFilters(prev => ({ ...prev, coauthorName: value }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select co-author..." />
              </SelectTrigger>
              <SelectContent showScrollHint>
                <SelectItem value="all">All Co-authors</SelectItem>
                {Array.from(filterOptions.coauthors).sort().map((name) => (
                  <SelectItem key={name} value={name}>
                    {name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Co-author Organization</label>
            <Select
              value={filters.coauthorOrgName}
              onValueChange={(value) => setFilters(prev => ({ ...prev, coauthorOrgName: value }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select organization..." />
              </SelectTrigger>
              <SelectContent showScrollHint>
                <SelectItem value="all">All Organizations</SelectItem>
                {Array.from(filterOptions.coauthorOrgs).sort().map((name) => (
                  <SelectItem key={name} value={name}>
                    {name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Add legends at the bottom */}
          <div className="mt-auto pt-8">
            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-sm font-semibold mb-4">Legend</h3>
              <div className="space-y-4">
                {/* Node type legend */}
                <div>
                  <h4 className="text-xs font-medium text-gray-500 mb-2">Node Types</h4>
                  <div className="space-y-3">
                    {['Researcher Org Name', 'Researcher Name', 'Co-author Name', 'Foreign Inflence Group'].map((type) => (
                      <div key={type} className="flex items-center gap-3">
                        <div className="w-6 flex-shrink-0">
                          <svg 
                            className="w-6 h-6" 
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 960 960"  // Changed to remove negative coordinates
                          >
                            <path
                              d={['Researcher Name', 'Co-author Name'].includes(type) 
                                ? 'M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z'
                                : 'M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z'}
                              transform="translate(0, 960)"  // Add this to shift the path down
                              fill={['Researcher Org Name', 'Researcher Name', 'Co-author Name', 'Co-author Org Name'].indexOf(type) === 0 ? '#1f77b4' : 
                                    ['Researcher Org Name', 'Researcher Name', 'Co-author Name', 'Co-author Org Name'].indexOf(type) === 1 ? '#2ca02c' :
                                    ['Researcher Org Name', 'Researcher Name', 'Co-author Name', 'Co-author Org Name'].indexOf(type) === 2 ? '#ff7f0e' : '#d62728'}
                            />
                          </svg>
                        </div>
                        <span className="text-sm text-gray-600">{type}</span>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Risk level legend */}
                <div>
                  <h4 className="text-xs font-medium text-gray-500 mb-2">Risk Levels</h4>
                  <div className="space-y-2">
                    {['HIGH', /* 'MEDIUM', */ 'LOW', /* 'UNCLASSIFIED' */].map((risk) => (
                      <div key={risk} className="flex items-center space-x-2">
                        <div 
                          className="w-6 h-6 rounded"
                          style={{ 
                            backgroundColor: risk === 'HIGH' ? '#ff0000' :
                                           risk === 'MEDIUM' ? '#ffa500' : 
                                           risk === 'LOW' ? '#999999' : '#808080'
                          }}
                        />
                        <span className={`text-sm text-gray-600 ${risk !== 'LOW' && risk !== 'UNCLASSIFIED' ? 'font-bold' : ''}`}>
                          {risk}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Add zoom control here, after the legends */}
            <div className="border-t border-gray-200 pt-4 mt-4">
              <h3 className="text-sm font-semibold mb-4">Zoom</h3>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleZoomChange(zoomLevel - 10)}
                  className="p-1 hover:bg-gray-100 rounded"
                  aria-label="Decrease size"
                >
                  <Minus className="w-4 h-4" />
                </button>
                
                <div className="flex-1 relative h-1 bg-gray-200 rounded">
                  <div 
                    className="absolute h-full bg-gray-900 rounded"
                    style={{ width: `${(zoomLevel - 10) / 190 * 100}%` }}
                  />
                </div>
                
                <button
                  onClick={() => handleZoomChange(zoomLevel + 10)}
                  className="p-1 hover:bg-gray-100 rounded"
                  aria-label="Increase size"
                >
                  <Plus className="w-4 h-4" />
                </button>
                
                <span className="text-sm text-gray-600 min-w-[3rem] text-right">
                  {zoomLevel}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div 
        className="flex-1 relative min-h-[500px]" // Add min-height
        ref={containerRef}
        style={{ height: '100%' }} // Explicitly set height
      >
        <svg 
          ref={svgRef} 
          className="w-full h-full"
          style={{ minHeight: '500px' }} // Add min-height to SVG as well
        />
      </div>

      {selectedNode && (
        <div className="w-64 p-4 border-l border-gray-200 overflow-y-auto relative">
          <button
            onClick={() => setSelectedNode(null)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
          
          {/* Comments Section - now with h-1/2 to take top half */}
          <div className="h-1/2 overflow-y-auto">
            <div className="mb-4">
              <h3 className="font-medium text-lg">{selectedNode.id}</h3>
              <p className="text-sm text-gray-500">
                Type: {selectedNode.group}
                <br />
                Risk Level: {selectedNode.risk}
              </p>
            </div>

            <div className="mb-4">
              <h4 className="font-medium mb-2">Comments</h4>
              <div className="space-y-2 mb-4 max-h-64 overflow-y-auto">
                {(comments[selectedNode.id] || []).map((comment) => (
                  <div key={comment.id} className="text-sm p-2 bg-gray-50 rounded relative group">
                    <button
                      onClick={() => handleDeleteComment(comment.id)}
                      className="absolute top-1 right-1 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                      title="Delete comment"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </button>
                    <div className="text-xs text-gray-500 mb-1">{comment.email}</div>
                    <p>{comment.text}</p>
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(comment.timestamp).toLocaleString([], { 
                        hour: '2-digit', 
                        minute: '2-digit', 
                        year: 'numeric', 
                        month: '2-digit', 
                        day: '2-digit' 
                      })}
                    </p>
                  </div>
                ))}
              </div>

              <div className="space-y-2 px-1">
                <Input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add a comment..."
                  className="w-full"
                />
                <Button onClick={handleAddComment} className="w-full">
                  Add Comment
                </Button>
              </div>
            </div>
          </div>

          {/* Separator */}
          <div className="border-t border-gray-200 my-4"></div>

          {/* Investigations Section - takes bottom half */}
          <div className="h-1/2">
            <h4 className="font-medium mb-4">Investigations</h4>
            <div className="text-center py-8 px-1">
              <p className="text-sm text-gray-500 mb-4">No ongoing investigations.</p>
              <Button 
                variant="outline" 
                className="w-full"
                onClick={() => setShowInvestigation(true)}
              >
                Start Investigation
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Add Investigation Dialog */}
      <InvestigationDialog
        isOpen={showInvestigation}
        onClose={() => setShowInvestigation(false)}
        selectedNode={selectedNode}
        networkData={data}
        onStartInvestigation={handleStartInvestigation}
      />
    </div>
  );
};

export default NetworkGraph;
